import React, { useState, useEffect } from 'react';
import PageTempate from './PageTemplate';
import IndustrialWasteDonutChart from '../components/IndustrialWasteDonutChart';
import { getAllIndustrialWasteReports } from '../services/industrialWasteService';
import { IndustrialWasteReport } from '../types/IndustrialWasteReport';
import { getAllCompanySewageReports } from '../services/companySewageService';
import { CompanySewageReport } from '../types/CompanySewageReport';
import CompanySewageBarGraph from '../components/CompanySewageBarGraph';

const IndustrialWastewater = () => {
  const [industrialWasteReports, setIndustrialWasteReports] = useState<
    IndustrialWasteReport[]
  >([]);
  const [companySewageReports, setCompanySewageReports] = useState<
    CompanySewageReport[]
  >([]);
  const [hasIndustrialWasteLoaded, setHasIndustrialWasteLoaded] =
    useState<boolean>(false);
  const [hasCompanySewageLoaded, setHasCompanySewageLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchIndustrialWasteReports = async () => {
      const retrievedReports = await getAllIndustrialWasteReports();
      if (Array.isArray(retrievedReports)) {
        setIndustrialWasteReports(retrievedReports);
        setHasIndustrialWasteLoaded(true);
      } else {
        console.error('Failed to fetch industrial waste reports');
      }
    };

    fetchIndustrialWasteReports();
  }, []);

  useEffect(() => {
    const fetchCompanySewageReports = async () => {
      const retrievedReports = await getAllCompanySewageReports();
      if (Array.isArray(retrievedReports)) {
        setCompanySewageReports(retrievedReports);
        setHasCompanySewageLoaded(true);
      } else {
        console.error('Failed to fetch company sewage reports');
      }
    };
    fetchCompanySewageReports();
  }, []);

  return (
    <PageTempate>
      <div className="App-section">
        <h2>Sector, Company vs Industrial Wastewater Percentage</h2>
        <div className="App-details-section">
          {hasIndustrialWasteLoaded ? (
            <IndustrialWasteDonutChart reportsToUse={industrialWasteReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>

      <div className="App-section">
        <h2>Company vs. Phosphorus Total Pollutant</h2>
        <div className="App-details-section">
          {hasCompanySewageLoaded ? (
            <CompanySewageBarGraph reportsToUse={companySewageReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </PageTempate>
  );
};

export default IndustrialWastewater;
