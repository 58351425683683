import { AirQualityReport } from '../types/AirQualityReport';
import { GRAPHS_URL } from './serviceConstants';

const AIR_QUALITY_PATH = `${GRAPHS_URL}/airEmissionExceedances`;

class AirQualityQueryService {
  protected path: string = AIR_QUALITY_PATH;

  async query(contaminant: string): Promise<AirQualityReport[] | string> {
    console.log(
      `Fetching data from: ${this.path}?contaminants=${encodeURI(contaminant)}`
    );
    try {
      const response = await fetch(
        this.path + '?contaminants=' + encodeURI(contaminant),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API response:', data);
      return data as AirQualityReport[];
    } catch (error) {
      console.error(`Error fetching data in ${this.constructor.name}:`, error);
      return `${error}`;
    }
  }
}

export const airQualityQueryService = new AirQualityQueryService();
export const queryAirQualityReports = (contaminant: string) =>
  airQualityQueryService.query(contaminant);
