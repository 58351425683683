import React, { useState, useEffect } from 'react';
import PageTempate from './PageTemplate';
import { ContaminantsReport } from '../types/ContaminantsReport';
import { getAllContaminantsReports } from '../services/allContaminantsService';
import AllContaminantsTable from '../components/AllContaminantsTable';

const AllContaminants = () => {
  const [contaminantsReports, setContaminantsReports] = useState<
    ContaminantsReport[]
  >([]);
  const [hasContaminantsLoaded, setHasContaminantsLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchAllContaminantsReports = async () => {
      const retrievedReports = await getAllContaminantsReports();
      if (Array.isArray(retrievedReports)) {
        setContaminantsReports(retrievedReports);
        setHasContaminantsLoaded(true);
      } else {
        console.error(
          'Page: Failed to fetch contaminants reports, received: ' +
            retrievedReports
        );
        setHasContaminantsLoaded(true);
      }
    };
    fetchAllContaminantsReports();
  }, []);

  return (
    <PageTempate>
      <div className="App-section">
        <h2>Contaminants: List of Contaminants by Type</h2>
        <div className="App-details-section">
          {hasContaminantsLoaded ? (
            <AllContaminantsTable reportsToUse={contaminantsReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </PageTempate>
  );
};

export default AllContaminants;
