import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { IndustrialWasteReport } from '../types/IndustrialWasteReport';

const IndustrialWasteDonutChart = (props: {
  reportsToUse: IndustrialWasteReport[];
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const industrialWasteReports = props.reportsToUse ?? [];

  const sectorDataPoints: Highcharts.PointOptionsObject[] = [];
  const companyDataPoints: Highcharts.PointOptionsObject[] = [];

  const leadIndWasteReports: IndustrialWasteReport[] = industrialWasteReports;

  for (const element of leadIndWasteReports) {
    const sector_index = sectorDataPoints.findIndex(function (item) {
      return item.name === element.sector;
    });

    if (sector_index != -1) {
      sectorDataPoints[sector_index].y =
        element.percent_share + (sectorDataPoints[sector_index].y ?? 0);
    } else {
      sectorDataPoints.push({
        name: element.sector,
        y: element.percent_share,
      });
    }

    companyDataPoints.push({
      name: element.company,
      y: element.percent_share,
    });
  }

  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Sector, Company vs Industrial Wastewater Unfiltered Total Lead',
      align: 'left',
    },
    subtitle: {
      text: '',
      align: 'left',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
      },
    },
    tooltip: {
      valueSuffix: '%',
    },
    series: [
      {
        name: 'Sectors',
        data: sectorDataPoints,
        size: '45%',
        color: '#ffffff',
        type: 'pie',
        dataLabels: {
          color: '#ffffff',
          distance: '-50%',
        },
      },
      {
        name: 'Companies',
        data: companyDataPoints,
        size: '80%',
        innerSize: '60%',
        type: 'pie',
      },
    ],
  };

  return (
    <>
      {industrialWasteReports.length != 0 ? (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
          />
        </div>
      ) : (
        <p>No data available to display the chart.</p>
      )}
    </>
  );
};

export default IndustrialWasteDonutChart;
