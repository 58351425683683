import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { BaseReport } from '../types/BaseReport';

const StreamHistogramChart = (props: { reportsToUse: BaseReport[] }) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const BaseReports = props.reportsToUse ?? [];
  const points: Highcharts.PointOptionsObject[] = [];

  if (BaseReports.length === 0) {
    return <p>No data available to display the chart.</p>;
  }

  const max: BaseReport = BaseReports.reduce(
    function (prev, current) {
      return prev &&
        Number(prev.measuredResult) > Number(current.measuredResult)
        ? prev
        : current;
    },
    {
      measuredResult: '0',
      measurementDate: '',
      measurementTime: '',
      resultUnits: '',
    }
  );

  for (let i = 0; i < Number(max.measuredResult); i += 5) {
    points.push({ name: String(i) + ' to ' + String(i + 5), x: i, y: 0 });
  }

  for (const element of BaseReports) {
    const point_index = points.findIndex(function (item) {
      if (typeof item.x !== 'undefined') {
        return (
          item.x <= Number(element.measuredResult) &&
          Number(element.measuredResult) <= item.x + 5
        );
      } else {
        return false;
      }
    });

    if (point_index != -1) {
      points[point_index].y = 1 + (points[point_index].y ?? 0);
    }
  }

  // Create chart options
  const options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Stream Health Histogram',
      align: 'left',
    },
    xAxis: {
      type: 'category',
      title: { text: 'Measured Result (mg/L)' },
    },
    yAxis: {
      title: { text: 'Frequency' },
    },
    series: [
      {
        name: 'Data',
        data: points,
        type: 'column',
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </div>
  );
};

export default StreamHistogramChart;
