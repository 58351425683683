import React, { useState, useEffect } from 'react';
import PageTempate from './PageTemplate';
import { getAllBaseReports } from '../services/streamHealthService';
import StreamHealthScatterplot from '../components/StreamHealthScatterplot';
import StreamHistogramChart from '../components/StreamHistogramGraph';
import { BaseReport } from '../types/BaseReport';

const StreamWaterQuality = () => {
  const [hasStreamHealthLoaded, setHasStreamHealthLoaded] =
    useState<boolean>(false);
  const [baseReports, setBaseReports] = useState<BaseReport[]>([]);

  useEffect(() => {
    const fetchBaseReports = async () => {
      const retrievedReports = await getAllBaseReports();
      if (Array.isArray(retrievedReports)) {
        setBaseReports(retrievedReports);
        setHasStreamHealthLoaded(true);
      } else {
        console.error('Failed to fetch notes');
      }
    };
    fetchBaseReports();
  }, []);

  return (
    <PageTempate>
      <div className="App-section">
        <h2>Stream Health: Alkalinity Measurements</h2>
        <div className="App-details-section">
          {hasStreamHealthLoaded ? (
            <StreamHealthScatterplot reportsToUse={baseReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>

      <div className="App-section">
        <h2>Stream Health Histogram</h2>
        <div className="App-details-section">
          {hasStreamHealthLoaded ? (
            <StreamHistogramChart reportsToUse={baseReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </PageTempate>
  );
};

export default StreamWaterQuality;
