export default abstract class BaseService<T> {
  protected abstract path: string;

  async fetchAll(): Promise<T[] | string> {
    console.log(`Fetching data from: ${this.path}`);
    try {
      const response = await fetch(this.path, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API response:', data);
      return data as T[];
    } catch (error) {
      console.error(`Error fetching data in ${this.constructor.name}:`, error);
      return `${error}`;
    }
  }
}
