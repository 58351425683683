import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Accordion } from 'react-bootstrap';
import '../styling/App.css';

interface ThemeSettings {
  currentTheme: string;
  backgroundColor: string;
  textColor: string;
}

function YawaNavbar() {
  const [themeSettings, setThemeSettings] = useState<ThemeSettings>({
    currentTheme: 'default',
    backgroundColor: '',
    textColor: '',
  });

  const applyThemeSettings = (settings: ThemeSettings) => {
    const { currentTheme, backgroundColor, textColor } = settings;
    document.documentElement.setAttribute('data-theme', currentTheme);

    if (backgroundColor) {
      document.documentElement.style.setProperty(
        '--background-color',
        backgroundColor
      );
      localStorage.setItem('customBackground', backgroundColor);
    } else {
      document.documentElement.style.removeProperty('--background-color');
      localStorage.removeItem('customBackground');
    }

    if (textColor) {
      document.documentElement.style.setProperty('--text-color', textColor);
      localStorage.setItem('customText', textColor);
    } else {
      document.documentElement.style.removeProperty('--text-color');
      localStorage.removeItem('customText');
    }

    localStorage.setItem('theme', currentTheme);
    setThemeSettings(settings);
  };

  useEffect(() => {
    const savedBackgroundColor = localStorage.getItem('customBackground') ?? '';
    const savedTextColor = localStorage.getItem('customText') ?? '';
    const savedTheme = localStorage.getItem('theme') ?? 'default';

    applyThemeSettings({
      currentTheme: savedTheme,
      backgroundColor: savedBackgroundColor,
      textColor: savedTextColor,
    });
  }, []);

  const handleThemeChange = (theme: string) => {
    if (theme === 'default') {
      applyThemeSettings({
        currentTheme: 'default',
        backgroundColor: '',
        textColor: '',
      });
    } else {
      applyThemeSettings({ ...themeSettings, currentTheme: theme });
    }
  };

  return (
    <Navbar variant="dark" className="bg-navbar">
      <Navbar.Brand href="/">YAWA</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown
            title="Data"
            id="data-nav-dropdown"
            className="bg-navbar-dropdown"
          >
            <NavDropdown.Item href="/airEmissions">
              Air Emissions
            </NavDropdown.Item>
            <NavDropdown.Item href="/industrialWastewater">
              Industrial Wastewater
            </NavDropdown.Item>
            <NavDropdown.Item href="/streamWaterQuality">
              Stream Water Quality
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title="View"
            id="view-nav-dropdown"
            className="bg-navbar-dropdown"
          >
            <div style={{ padding: '10px' }}>
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="themeSelect" style={{ marginRight: '5px' }}>
                  Theme:
                </label>
                <select
                  id="themeSelect"
                  className="dropdown-select"
                  value={themeSettings.currentTheme}
                  onChange={(e) => handleThemeChange(e.target.value)}
                >
                  <option value="default">Default</option>
                  <option value="dark">Dark</option>
                  <option value="light">Light</option>
                  <option value="blue">Blue</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Customize Your Own Background
                  </Accordion.Header>
                  <Accordion.Body>
                    <div style={{ marginBottom: '10px' }}>
                      <label
                        htmlFor="backgroundColor"
                        style={{ marginRight: '5px' }}
                      >
                        Background Color:
                      </label>
                      <input
                        type="color"
                        id="backgroundColor"
                        className="dropdown-color-input"
                        value={themeSettings.backgroundColor}
                        onChange={(e) => {
                          const color = e.target.value;
                          applyThemeSettings({
                            ...themeSettings,
                            backgroundColor: color,
                            currentTheme: 'custom',
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="textColor" style={{ marginRight: '5px' }}>
                        Text Color:
                      </label>
                      <input
                        type="color"
                        id="textColor"
                        className="dropdown-color-input"
                        value={themeSettings.textColor}
                        onChange={(e) => {
                          const color = e.target.value;
                          applyThemeSettings({
                            ...themeSettings,
                            textColor: color,
                            currentTheme: 'custom',
                          });
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </NavDropdown>

          <NavDropdown
            title="Tables"
            id="basic-nav-dropdown-tables"
            className="bg-navbar-dropdown"
          >
            <NavDropdown.Item href="/allContaminants">
              All Contaminants
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default YawaNavbar;
