import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CompanySewageReport } from '../types/CompanySewageReport';
import { getAllCompanySewageReports } from '../services/companySewageService';

const CompanySewageBarGraph = (props: {
  reportsToUse?: CompanySewageReport[];
}) => {
  const [reports, setReports] = useState<CompanySewageReport[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (props.reportsToUse && props.reportsToUse.length > 0) {
        setReports(props.reportsToUse);
      } else {
        try {
          const data = await getAllCompanySewageReports();
          setReports(data as CompanySewageReport[]);
        } catch (error) {
          console.error('Error fetching air quality reports:', error);
        }
      }
    };

    fetchData();
  }, [props.reportsToUse]);

  const combinedData = reports.reduce(
    (acc: { [key: string]: number }, report) => {
      const company = report.companyName ?? 'Unknown';
      if (acc[company]) {
        acc[company] += report.totalReportedValue ?? 0;
      } else {
        acc[company] = report.totalReportedValue ?? 0;
      }
      return acc;
    },
    {}
  );

  const districts = Object.keys(combinedData);
  const exceedances = Object.values(combinedData);

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Company vs. Phosphorus Total Pollutant',
    },
    xAxis: {
      categories: districts,
      title: {
        text: 'Company',
      },
      labels: {
        allowOverlap: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total Unfiltered Phosphorus (KG/D)',
      },
      allowDecimals: false,
      labels: {
        allowOverlap: true,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><br/>',
      pointFormat: '<b>{point.y}</b> KG/D',
    },
    series: [
      {
        type: 'column',
        dataSorting: {
          enabled: true,
        },
        name: 'Phosphorus',
        data: exceedances,
        color: '#7cb5ec',
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      {reports.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <p>No data available to display the chart.</p>
      )}
    </div>
  );
};

export default CompanySewageBarGraph;
