import React, { useState, useEffect } from 'react';
import PageTempate from './PageTemplate';
import AirQualityGraph from '../components/AirQualityGraph';
import { AirQualityReport } from '../types/AirQualityReport';
import { queryAirQualityReports } from '../services/airQualityQueryService';
import { getAllContaminantsReports } from '../services/allContaminantsService';

const AirEmissions = () => {
  const [airQualityReports, setAirQualityReports] = useState<
    AirQualityReport[]
  >([]);
  const [hasReportsLoaded, setHasReportsLoaded] = useState<boolean>(false);

  const [selectedContaminant, setSelectedContaminant] = useState<string>('');
  const [contaminantOptions, setContaminantOptions] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedContaminant(e.target.value);
  };

  useEffect(() => {
    const fetchAirQualityReports = async () => {
      // Get all available contaminants
      const retrievedContaminantReports = await getAllContaminantsReports();
      if (!Array.isArray(retrievedContaminantReports)) {
        console.error('Failed to fetch contaminant reports');
        return;
      }

      // Set initial value of contaminant
      if (
        retrievedContaminantReports.length > 0 &&
        selectedContaminant === '' &&
        typeof contaminantOptions[0] !== 'undefined'
      ) {
        setSelectedContaminant(contaminantOptions[0]);
      }

      // Get air reports
      const retrievedAirReports =
        await queryAirQualityReports(selectedContaminant);
      if (!Array.isArray(retrievedAirReports)) {
        console.error('Failed to fetch air quality reports');
        return;
      }

      setContaminantOptions(
        retrievedContaminantReports
          .filter((value) => value.source === 'AIR EMISSIONS')
          .map((item) => item.contaminant)
          .filter((value, index, self) => self.indexOf(value) === index)
      );

      setAirQualityReports(retrievedAirReports);
      setHasReportsLoaded(true);
    };
    fetchAirQualityReports();
  }, [selectedContaminant]);

  return (
    <PageTempate>
      <div className="App-section">
        <h2>Air Quality: Exceedances by District</h2>
        <div className="App-details-section">
          {hasReportsLoaded ? (
            <select
              value={selectedContaminant}
              onChange={handleChange}
              className="dropdown"
            >
              {contaminantOptions.map((item) => (
                <option key={String(item)}>{item}</option>
              ))}
            </select>
          ) : (
            <p>Loading...</p>
          )}
          {hasReportsLoaded ? (
            <AirQualityGraph
              reportsToUse={airQualityReports}
              contaminant={selectedContaminant}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </PageTempate>
  );
};

export default AirEmissions;
