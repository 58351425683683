import React, { ReactNode } from 'react';
import '../styling/App.css';

interface ContainerProps {
  children: ReactNode;
}

const PageTemplate: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="App">
      <div className="App-children">{children}</div>
    </div>
  );
};

export default PageTemplate;
