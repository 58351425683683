// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #e0f7fa;
  --text-color: #333333;
  --accent-color: #293241;
  --highlight-color: #ee6c4d;
  --secondary-color: #293241;
}

[data-theme='dark'] {
  --background-color: #0d1117;
  --text-color: #c9d1d9;
  --accent-color: #161b22;
  --highlight-color: #58a6ff;
  --secondary-color: #21262d;
}

[data-theme='light'] {
  --background-color: #eaf0f8;
  --text-color: #1f2933;
  --accent-color: #90a4d4;
  --highlight-color: #3b5998;
  --secondary-color: #a1c3e6;
}

[data-theme='blue'] {
  --background-color: #0d1b2a;
  --text-color: #e0e6ed;
  --accent-color: #1b263b;
  --highlight-color: #3a506b;
  --secondary-color: #5bc0eb;
}
`, "",{"version":3,"sources":["webpack://./src/styling/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yCAAyC;EACzC,wBAAwB;EACxB;;cAEY;AACd;;AAEA;EACE;aACW;AACb;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;AAC5B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--background-color);\n  color: var(--text-color);\n  transition:\n    background-color 0.3s,\n    color 0.3s;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n:root {\n  --background-color: #e0f7fa;\n  --text-color: #333333;\n  --accent-color: #293241;\n  --highlight-color: #ee6c4d;\n  --secondary-color: #293241;\n}\n\n[data-theme='dark'] {\n  --background-color: #0d1117;\n  --text-color: #c9d1d9;\n  --accent-color: #161b22;\n  --highlight-color: #58a6ff;\n  --secondary-color: #21262d;\n}\n\n[data-theme='light'] {\n  --background-color: #eaf0f8;\n  --text-color: #1f2933;\n  --accent-color: #90a4d4;\n  --highlight-color: #3b5998;\n  --secondary-color: #a1c3e6;\n}\n\n[data-theme='blue'] {\n  --background-color: #0d1b2a;\n  --text-color: #e0e6ed;\n  --accent-color: #1b263b;\n  --highlight-color: #3a506b;\n  --secondary-color: #5bc0eb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
