import { CompanySewageReport } from '../types/CompanySewageReport';
import BaseService from './BaseService';
import { GRAPHS_URL } from './serviceConstants';

const COMPANY_SEWAGE_PATH = `${GRAPHS_URL}/industrialWaterPollution`;

class CompanySewageService extends BaseService<CompanySewageReport> {
  protected path = COMPANY_SEWAGE_PATH;
}

export const companySewageService = new CompanySewageService();
export const getAllCompanySewageReports = () => companySewageService.fetchAll();
