import { BaseReport } from '../types/BaseReport';
import BaseService from './BaseService';
import { GRAPHS_URL } from './serviceConstants';

const STREAM_HEALTH_PATH = `${GRAPHS_URL}/streamContaminants`;

class StreamHealthService extends BaseService<BaseReport> {
  protected path = STREAM_HEALTH_PATH;
}

export const streamHealthService = new StreamHealthService();
export const getAllBaseReports = () => streamHealthService.fetchAll();
