import React from 'react';
import { ContaminantsReport } from '../types/ContaminantsReport';

const splitAndSortContaminantsByType = (
  givenReports: ContaminantsReport[]
): { [key: string]: string[] } => {
  const split: { [key: string]: string[] } = {};

  if (givenReports == null || givenReports.length == 0) {
    return {};
  }

  givenReports.forEach((report) => {
    if (!split[report.source]) {
      split[report.source] = [];
    }
    split[report.source].push(report.contaminant);
  });

  const keys = Object.keys(split);
  keys.forEach((key) => {
    split[key].sort((a, b) => a.localeCompare(b));
  });
  return split;
};

const AllContaminantsTable = (props: {
  reportsToUse: ContaminantsReport[];
}) => {
  const contaminantsReports = props.reportsToUse ?? [];
  const filteredReports = splitAndSortContaminantsByType(contaminantsReports);
  return (
    <div>
      {contaminantsReports.length > 0 ? (
        (Object.keys(filteredReports) as Array<keyof string>).map((source) => (
          <div key={source.toString()}>
            <h3>
              <b>{source.toString()}</b>
            </h3>
            <details>
              <summary>Click to expand</summary>
              <ul>
                {filteredReports[source.toString()].map((contaminant) => (
                  <li key={contaminant}>{contaminant}</li>
                ))}
              </ul>
            </details>
          </div>
        ))
      ) : (
        <p>No data available to display the table.</p>
      )}
    </div>
  );
};

export default AllContaminantsTable;
