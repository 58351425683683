import React from 'react';
import PageTempate from './PageTemplate';

const Home = () => {
  return (
    <PageTempate>
      <div className="App-section">
        <h1>YAWA</h1>
        <h2>Product Pitch</h2>
        <div className="App-details-section">
          <p>
            Introducing YAWA: Your Go-To Solution for Tracking Industrial
            Pollution! In a world where pollution is an escalating concern, it
            {"'"}s crucial to comprehend how industrial activities impact our
            air and water. YAWA simplifies the visualization of the connections
            between industrial wastewater, air emissions, and stream water
            quality. Our platform helps identify instances where pollution
            exceeds safe levels and its real-time impact on water bodies.
            Whether you{"'"}re a researcher, policymaker, or environmental
            activist, YAWA will equip you with the insights needed to make
            informed decisions. Our intuitive system breaks down complex data
            into easily understandable, customizable graphs and a simple
            dashboard with some of the most important information at a glance.
            Never feel astray again - with YAWA{"'"}s data at your fingertips,
            you{"'"}ll never find yourself lost in the data again!
          </p>
        </div>
      </div>
    </PageTempate>
  );
};

export default Home;
