import { IndustrialWasteReport } from '../types/IndustrialWasteReport';
import BaseService from './BaseService';
import { GRAPHS_URL } from './serviceConstants';

const INDUSTRIAL_WASTE_PATH = `${GRAPHS_URL}/industrialWaste`;

class IndustrialWasteService extends BaseService<IndustrialWasteReport> {
  protected path = INDUSTRIAL_WASTE_PATH;
}

export const industrialWasteService = new IndustrialWasteService();
export const getAllIndustrialWasteReports = () =>
  industrialWasteService.fetchAll();
