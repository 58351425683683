export const date_format_yyyymmdd_to_date = (
  dateString: string
): Date | null => {
  try {
    const year = parseInt(dateString.substring(0, 4));
    const month = parseInt(dateString.substring(4, 6));
    const day = parseInt(dateString.substring(6, 8));
    let date = new Date(year, month - 1, day);
    date = new Date(date.getTime());
    return date;
  } catch (error) {
    console.error(`Failed parsing date (${dateString}): ${error}`);
    return null;
  }
};
