import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AirQualityReport } from '../types/AirQualityReport';

const AirQualityGraph = (props: {
  reportsToUse: AirQualityReport[];
  contaminant: string;
}) => {
  const airQualityReports = props.reportsToUse ?? [];

  const combinedData = airQualityReports.reduce(
    (acc: { [key: string]: number }, airQualityReports) => {
      const district = airQualityReports.district ?? 'Unknown';
      if (acc[district]) {
        acc[district] += airQualityReports.numberOfExceedances;
      } else {
        acc[district] = airQualityReports.numberOfExceedances;
      }
      return acc;
    },
    {}
  );

  const districts = Object.keys(combinedData);
  const exceedances = Object.values(combinedData);

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: "Number of Exceedances of '" + props.contaminant + "' per District",
    },
    xAxis: {
      categories: districts,
      title: {
        text: 'District',
      },
      labels: {
        allowOverlap: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Exceedances',
      },
      allowDecimals: false,
      labels: {
        allowOverlap: true,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><br/>',
      pointFormat: '<b>{point.y}</b> exceedances',
    },
    series: [
      {
        type: 'column',
        name: 'Exceedances',
        data: exceedances,
        color: '#7cb5ec',
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      {airQualityReports.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <p>No data available to display the chart.</p>
      )}
    </div>
  );
};

export default AirQualityGraph;
