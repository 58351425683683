import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AirEmissions from './pages/AirEmissions';
import StreamWaterQuality from './pages/StreamWaterQuality';
import IndustrialWastewater from './pages/IndustrialWastewater';
import YawaNavbar from './components/YawaNavbar';
import './styling/App.css';
import AllContaminants from './pages/AllContaminants';

const App: React.FC = () => {
  return (
    <div className="App">
      <YawaNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/airEmissions" element={<AirEmissions />} />
        <Route path="/streamWaterQuality" element={<StreamWaterQuality />} />
        <Route
          path="/industrialWastewater"
          element={<IndustrialWastewater />}
        />
        <Route path="/allContaminants" element={<AllContaminants />} />
      </Routes>
    </div>
  );
};

export default App;
