import { ContaminantsReport } from '../types/ContaminantsReport';
import BaseService from './BaseService';
import { TABLES_URL } from './serviceConstants';

const ALL_CONTAMINANTS_PATH = `${TABLES_URL}/allContaminants`;

class AllContaminantsService extends BaseService<ContaminantsReport> {
  protected path = ALL_CONTAMINANTS_PATH;
}

export const allContaminantsService = new AllContaminantsService();
export const getAllContaminantsReports = () =>
  allContaminantsService.fetchAll();
